/*global angular: false */

let am = angular.module('mtz-directives');

am.directive('languageSelect', () => {
  let languages = {
    en: 'English',
    fr: 'French'
  };

  let template = '<select class="form-control language-select" ' +
    'ng-options="abbrev as name for (abbrev, name) in languages">' +
    '</select>';

  return {
    restrict: 'AE',
    replace: true,
    require: '^ngModel',
    template: template,
    link(scope) {
      scope.languages = languages;
    }
  };
});
